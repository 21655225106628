import { Accordion, Carousel, Col, Container, Row } from 'react-bootstrap';
import { useContext, useEffect } from 'react';
import * as S from './style';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { DefaultButton } from '../../components/DefaultButton';
import CarouselWrapper from '../../components/CarouselWrapper';
import { useCampaign } from '../../context/CampaignContext';
import saude from '../../assets/img/logo-saude.png'
import { INITIAL_FORM_DATA, SearchFormContext } from '../../context/SearchFormDataContext';

const INTERVAL = 3000;

export default function Home(){
    const { campaignData } = useCampaign();
    const { updateFormData } = useContext(SearchFormContext);

    useEffect(() => {
        updateFormData(INITIAL_FORM_DATA);
    }, [updateFormData])

    const renderCarousels = () => campaignData.banners?.map((banner, idx) => {
        return (
            <Carousel.Item key={`banner-${idx}`} >
            <picture>
                <source srcSet={banner.img?.mobile} media="(max-width: 30em)" />
                <source srcSet={banner.img?.desktop} />
                <img src={banner.img?.desktop} alt="banner 1" />
            </picture>
            <div className='texto'>
                <h1>{banner?.title}</h1>
                <p>{banner?.subtitle}</p>
            </div>
        </Carousel.Item>
        )
    })

    return<>
        <Header />

        <S.Banner>
            <CarouselWrapper interval={INTERVAL}>
                {renderCarousels()}
            </CarouselWrapper>
        </S.Banner>

        <S.Beneficios>
            <S.SectionTitle>Conheça os benefícios de ter o cartão do palmeiras!</S.SectionTitle>
            <S.ContainerParceirosNovo>
                <div>
                    <S.Card>
                        <div>
                            <S.CardImage src={saude} alt="Saúde" />
                            <S.CardTitle>Saúde</S.CardTitle>
                        </div>
                        <S.CardDesc>Descontos em consultas e exames. Consultas a partir de R$ 26 em clínicas parceiras</S.CardDesc>
                    </S.Card>
                </div>
            </S.ContainerParceirosNovo>
            <S.PecaJa>
                <a href="https://vendas.cartaodetodospalmeiras.com.br/" className="peca-ja">Peça já o seu</a>
            </S.PecaJa>
        </S.Beneficios>

        <S.Mensalidade>
            <Container>
                    <picture>
                        <source srcSet={campaignData.imgs.preco.bgMobile} media="(max-width: 48em)" />
                        <source srcSet={campaignData.imgs.preco.bgDesktop} />
                        <img className="mensalidade-bg" src={campaignData.imgs.preco.bgDesktop} alt="banner torcida" />
                    </picture>
                    <div className="linear-gradient-decoration" />
                <Row>
                    <Col md={6}>
                        <h1>Cartão de TODOS {campaignData.teamName}</h1>
                        <p>Com o Cartão de TODOS, sua família passa por consultas, dentistas e faz exames na hora que precisa, pelo preço que pode pagar. E você ainda tem acesso a descontos em educação e lazer.</p>
                        <Row>
                            <div className="mensalidade-prices">
                            <fieldset tabIndex={-1} aria-disabled={true} className="preco centro">
                                <legend><span>Mensalidade de apenas</span></legend>
                                <p>{campaignData.prices.monthlyFee.substring(0,2)}<span className='preco-g'>{campaignData.prices.monthlyFee.substring(3,5)}</span><span className='preco-centavo'>{campaignData.prices.monthlyFee.substring(5,8)}</span></p>
                                <h3>PARA TODA A FAMÍLIA</h3>
                            </fieldset>
                            <fieldset tabIndex={-1} aria-disabled={true} className='preco'>
                                <legend><span>Consultas</span></legend>
                                <div className='consultas'>
                                    <h3>Clínico<br />geral</h3>
                                    <p><span className="currency-unit">{campaignData.prices.appointment.general.substring(0,2)}</span><span>{campaignData.prices.appointment.general.substring(3,5)}</span></p>
                                </div>
                                <div className='consultas'>
                                    <h3>Demais<br />áreas</h3>
                                    <p><span className="currency-unit">{campaignData.prices.appointment.specialist.substring(0,2)}</span><span>{campaignData.prices.appointment.specialist.substring(3,5)}</span></p>
                                </div>
                            </fieldset>
                            </div>
                        </Row>
                        <p className="disclaimer">Anuidade de R$ 30 reais, pode ser parcelada em até 12 vezes no cartão. E você ganha sua carteirinha personalizada, saúde, educação e lazer!</p>
                        <a className="subBt" href={campaignData.salesUrl}><DefaultButton>solicitar meu cartão</DefaultButton></a>
                        <picture>
                            <source srcSet={campaignData.imgs.preco.cartaoPreco} />
                            <img className="mensalidade-cartao" src={campaignData.imgs.preco.cartaoPreco} alt="cartao de todos" />
                        </picture>
                    </Col>
                </Row>
            </Container>
        </S.Mensalidade>

        {/* <S.Rede>
            <h1>Aproveite a nossa rede<br />com <span>mais de 12.000 parceiros.</span></h1>
            <Container>
                <Row>
                    <Col md='2' xs='6'>
                        <img src={amorSaude} alt="logo amor e saúde" />
                        <p>consulta a partir<br />de <span>{campaignData.prices.appointment.general}</span></p>
                    </Col>
                    <Col md='2' xs='6'>
                        <img src={cna} alt="logo CNA" />
                        <p><span>35%</span> no 1º estágio e<br /><span>15%</span> no 2º estágio</p>
                    </Col>
                    <Col md='2' xs='6'>
                        <img src={drogasil} alt="logo Drogasil" />
                        <p>até <span>35%</span> em<br />medicamentos</p>
                    </Col>
                    <Col md='2' xs='6'>
                        <img src={estacio} alt="Logo Estácio" />
                        <p><span>40%</span> nos cursos de<br />graduação e pós-graduação</p>
                    </Col>
                    <Col md='2' xs='6'>
                        <img src={raia} alt="Logo Droga Raia" />
                        <p>até <span>35%</span> em<br />medicamentos</p>
                    </Col>
                    <Col md='2' xs='6'>
                        <img src={refuturiza} alt="Logo Refuturiza" />
                        <p><span>{campaignData.prices.partners.refuturiza}</span> por mês</p>
                    </Col>
                </Row>
            </Container>
        </S.Rede> */}

      
        <S.Perguntas>
            <div className="linear-gradient-decoration-top" />
            <div className="background-container">
            <picture>
                <source srcSet={campaignData.imgs.faq.bgMobile} media="(max-width: 80em)" />
                <source srcSet={campaignData.imgs.faq.bgDesktop} />
                <img src={campaignData.imgs.faq.bgDesktop} alt="banner torcida" />
            </picture>
            </div>
            <Container>
                <Row>
                    <Col md='6'>
                        <div className='texto'>
                            <h1>PERGUNTAS FREQUENTES</h1>
                        </div>
                    </Col>
                    <Col md='6'>
                    <Accordion>
                        <Accordion.Item eventKey="0" className='acordion-item'>
                            <Accordion.Header>
                                <div className='acordion-titulo'>Como pedir meu Cartão de Todos {campaignData.teamName}?</div>
                            </Accordion.Header>
                            <Accordion.Body>
                                Pelo Site: entre em "Solicite seu cartão" preencha o campo "CEP" e siga os processos e passos; Pelo Telefone: 4003-5801; ou Pessoalmente: nas Franquias do Cartão de TODOS mais próxima da sua residência.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1" className='acordion-item'>
                            <Accordion.Header>
                                <div className='acordion-titulo'>Quanto custa o Cartão de Todos {campaignData.teamName}?</div>
                            </Accordion.Header>
                            <Accordion.Body>
                                O valor da adesão do Cartão de Todos é de {campaignData.prices.monthlyFee}, a mensalidade tem o valor de {campaignData.prices.monthlyFee} e a anuidade para ter o cartão do seu time do coração é de R$30, podendo ser dividida em até 12 vezes de R$2,50.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2" className='acordion-item'>
                            <Accordion.Header>
                                <div className='acordion-titulo'>Já tenho o Cartão de TODOS, como eu faço para transferir para o Cartão de Todos {campaignData.teamName}?</div>
                            </Accordion.Header>
                            <Accordion.Body>
                                Vá até a franquia do Cartão de TODOS mais próxima de sua residência e solicite o upgrade para o seu plano. O filiado pagará pelo upgrade o valor da anuidade e a nova carteirinha. A anuidade possui o valor de R$30, podendo ser dividida em até 12 vezes de R$2,50 e a carteirinha tem o valor sugerido de R$10.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3" className='acordion-item'>
                            <Accordion.Header>
                                <div className='acordion-titulo'>Como o Cartão de Todos {campaignData.teamName} funciona?</div>
                            </Accordion.Header>
                            <Accordion.Body>
                                O Cartão de TODOS é uma empresa que trabalha no ramo de cartões de desconto, oferecendo a intermediação de descontos entre pessoas físicas (aderentes) e pessoas jurídicas (empresas parceiras). Ao aderir ao Cartão de TODOS e realizar o pagamento de suas mensalidades, o cliente passa a ter benefícios de descontos em uma vasta rede de empresas parceiras nas áreas de saúde, educação e lazer, espalhadas em todo o brasil. Um ótimo exemplo destes benefícios são os custos de consultas nas clínicas parceiras - R$ 26 clínico geral e R$ 35 demais áreas, além de descontos especiais em procedimentos, tratamentos odontológicos completos e exames. O cliente paga uma taxa de emissão do cartão no valor de R$ 29,70 (paga no ato do contrato) e as mensalidades são no valor de R$ 29,70. A anuidade é paga pelo cliente em até 12 parcelas de R$ 2,50. Você pode colocar como dependentes cônjuge e filhos até 21 anos.
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                    </Col>
                </Row>
            </Container>
            <div className="linear-gradient-decoration" />
        </S.Perguntas>
        <Footer />
    </>
}